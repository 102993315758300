import { container, title } from 'assets/jss/material-kit-react';

const testimonialsStyle = theme => ({
  section: {
    padding: '10px 0',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0',
    },
  },
  container,
  marginAuto: {
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
  },
  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
    fontFamily: 'Quattrocento',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  icon: {
    margin: '30px 0',
  },
  testimonial: {
    marginBottom: '2rem',
  },
  customer: {
    fontStyle: 'italic',
  },
  quote: {
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default testimonialsStyle;
