import React, { Fragment, useEffect } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import LazyLoad from 'react-lazyload';
import { isMobile } from 'react-device-detect';

// nodejs library that concatenates classes
import classNames from 'classnames';
// react component for creating beautiful carousel
import Carousel from 'react-slick';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import HomeSnackbar from 'components/HomeSnackbar/HomeSnackbar';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Slogan from 'components/Slogan/Slogan';
import Spinner from 'components/Spinner/Spinner';
// sections for this page
import SectionMap from 'components/HomePage/SectionMap';
import SectionAbout from 'components/HomePage/SectionAbout';
import SectionPartners from 'components/HomePage/SectionPartners';
import SectionTestimonials from 'components/HomePage/SectionTestimonials';

import componentsStyle from 'assets/jss/material-kit-react/views/homePage';

export const query = graphql`
  query HomepageQuery($lang: String = "fr-fr") {
    prismicHomepage(lang: { eq: $lang }) {
      alternate_languages {
        lang
        type
        uid
      }
      data {
        about_us {
          description {
            raw
          }
          icon
          title {
            raw
          }
        }
        about_us_section_title {
          raw
        }
        hero_images {
          image {
            dimensions {
              height
              width
            }
            thumbnails {
              mobile {
                url
              }
            }
            url
          }
        }
        map_section_description {
          raw
        }
        map_section_title {
          raw
        }
        message {
          raw
        }
        partners {
          link {
            raw
          }
          logo {
            url
          }
        }
        partners_section_title {
          raw
        }
        slogan {
          raw
        }
        testimonials {
          customer {
            raw
            text
          }
          pdf {
            link_type
            url
          }
          testimonial {
            raw
          }
        }
        testimonials_section_title {
          raw
        }
        tours {
          destination {
            document {
              ... on PrismicDestination {
                id
                lang
                type
                uid
                url
                data {
                  name {
                    raw
                  }
                }
              }
            }
          }
          message {
            raw
          }
        }
      }
      lang
    }
    prismicMenu(lang: { eq: $lang }) {
      data {
        links {
          label {
            raw
          }
          link {
            lang
            type
            url
          }
        }
      }
    }
  }
`;

const HomePage = ({ data, classes }) => {
  if (!data) return null;

  const doc = data.prismicHomepage.data;
  const { lang } = data.prismicHomepage;
  const alternateLanguages = data.prismicHomepage.alternate_languages;

  const activeDocMeta = { lang, alternateLanguages };

  const slogan = RichText.asText(doc.slogan.raw);
  const heroImages = doc.hero_images;

  const settings = {
    className: 'home-slider',
    dots: true,
    lazyload: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const { tours } = data.prismicHomepage.data;

  const menu = data.prismicMenu.data;
  if (!menu) return null;

  useEffect(() => {}, []);

  return (
    <Layout activeDocMeta={activeDocMeta} menu={menu} tours={tours}>
      <SEO
        title={`${slogan}| Servitours, Destination Management Company`}
        image={heroImages ? heroImages[0].image : null}
        description={slogan}
      />
      <Fragment>
        <Carousel {...settings}>
          {heroImages.map(document => (
            <div key={document.image.url} className={classes.homeSlide}>
              {isMobile && (
                <img
                  src={document.image.thumbnails.mobile.url}
                  alt="Servitours"
                  className={classes.slideImage}
                />
              )}
              {!isMobile && (
                <img src={document.image.url} alt="Servitours" className={classes.slideImage} />
              )}
              <Slogan slogan={slogan} />
            </div>
          ))}
        </Carousel>
      </Fragment>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionMap title={doc.map_section_title} description={doc.map_section_description} lang={lang} />

        {/* <LazyLoad height={200} once offset={[-200, 0]} placeholder={<Spinner />}> */}
          <SectionAbout data={doc.about_us} lang={lang} title={doc.about_us_section_title} />
        {/* </LazyLoad> */}

        {/* <LazyLoad height={200} once offset={[-200, 0]} placeholder={<Spinner />}> */}
          <SectionTestimonials data={doc.testimonials} lang={lang} title={doc.testimonials_section_title} />
        {/* </LazyLoad> */}

        {/* <LazyLoad height={200} once offset={[-200, 0]} placeholder={<Spinner />}> */}
          <SectionPartners data={doc.partners} title={doc.partners_section_title} />
        {/* </LazyLoad> */}
      </div>
      <HomeSnackbar lang={lang} />
    </Layout>
  );
};

export default withStyles(componentsStyle)(HomePage);
