/* eslint-disable no-console */
import React, { Fragment, useEffect, useRef } from 'react';
import { RichText } from 'prismic-reactjs';
import Prismic from 'prismic-javascript';

// nodejs library that concatenates classes
import classNames from 'classnames';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-sidebar/src/L.Control.Sidebar';
import 'leaflet-sidebar/src/L.Control.Sidebar.css';

// Google Maps Geocoding
import Geocode from 'react-geocode';

const HomeMap = ({ children, classes, lang }) => {
  Geocode.setApiKey('AIzaSyCQ3zlRZAMDsQJ6dcdURgmwk7K74xGbD54');
  const mapRef = useRef(null);
  const sidebarRef = useRef(null);
  const destinationLayerRef = useRef(null);
  const regionLayerRef = useRef(null);
  const provinceLayerRef = useRef(null);
  const apiEndpoint = 'https://servitours.prismic.io/api/v2';
  const client = Prismic.client(apiEndpoint);

  useEffect(() => {
    const container = L.DomUtil.get('map');

    if (container != null) {
      container._leaflet_id = null;
    }
  }, []);

  useEffect(() => {
    mapRef.current = L.map('map', {
      center: [46.0, 2.0],
      zoom: 6,
      maxBounds: [[52, 14.194335937500002], [40, -10.195312500000002]],
      scrollWheelZoom: false,
      layers: [
        L.tileLayer(
          'https://api.mapbox.com/styles/v1/boy2king/cjx1k0do401yi1cp0qcc5hkf1/tiles/256/{z}/{x}/{y}?access_token={accessToken}',
          {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> Servitours',
            accessToken:
              'pk.eyJ1IjoiYm95MmtpbmciLCJhIjoiY2p4MWp4b2x3MDBkczQzbnNnZzBzam96aCJ9.iSoo8WNkwkAdP6xZ41nj0Q',
            minZoom: 6,
            maxZoom: 9,
          },
        ),
      ],
    })
      .on('zoomend', function onMapZoom() {
        if (mapRef.current.getZoom() < 7) {
          mapRef.current.addLayer(regionLayerRef.current);
          mapRef.current.addLayer(provinceLayerRef.current);
          mapRef.current.removeLayer(destinationLayerRef.current);
        }
        if (mapRef.current.getZoom() >= 7) {
          mapRef.current.removeLayer(regionLayerRef.current);
          mapRef.current.removeLayer(provinceLayerRef.current);
          mapRef.current.addLayer(destinationLayerRef.current);
        }
      })
      .on('click', function onMapClick() {
        sidebarRef.current.hide();
      });
  }, []);

  useEffect(() => {
    sidebarRef.current = L.control.sidebar('sidebar', {
      closeButton: true,
      position: 'right',
    });
    mapRef.current.addControl(sidebarRef.current);
  }, []);

  // add region layer
  useEffect(() => {
    regionLayerRef.current = L.layerGroup().addTo(mapRef.current);
  }, []);

  // update region markers
  useEffect(() => {
    regionLayerRef.current.clearLayers();

    client
      .query(Prismic.Predicates.at('document.type', 'region'), {
        lang: lang,
        pageSize: 100,
      })
      .then(prismicResponse => {
        // response is the response object, response.results holds the documents
        prismicResponse.results.forEach(document => {
          const icon = L.divIcon({
            className: classNames(classes.marker),
            html: `<div>
  						<svg className=${classes.markerIcon} height="16" width="16" viewBox="0 0 120 120" version="1.1"
            		xmlns="http://www.w3.org/2000/svg">
            		<circle cx="60" cy="60" r="50" fill="white"/>
          		</svg>
  						<div>${RichText.asText(document.data.name)}</div>
  						</div>`,
          });

          const onMarkerClick = e => {
            mapRef.current.setView(e.latlng, 8);
            destinationLayerRef.current.addTo(mapRef.current);
            mapRef.current.removeLayer(regionLayerRef.current);
            mapRef.current.removeLayer(provinceLayerRef.current);
          };

          if (
            Object.prototype.hasOwnProperty.call(document.data.localization, 'latitude') &&
            Object.prototype.hasOwnProperty.call(document.data.localization, 'longitude')
          ) {
            const lat = document.data.localization.latitude;
            const lng = document.data.localization.longitude;
            const latLng = L.latLng(lat, lng);
            L.marker(latLng, { icon })
              .addTo(regionLayerRef.current)
              .on('click', onMarkerClick);
          } else {
            Geocode.fromAddress(`${RichText.asText(document.data.name)}, France`).then(
              geocodeResponse => {
                const { lat, lng } = geocodeResponse.results[0].geometry.location;
                const latLng = L.latLng(lat, lng);

                L.marker(latLng, { icon })
                  .addTo(regionLayerRef.current)
                  .on('click', onMarkerClick);
              },
              error => {
                console.error(error);
              },
            );
          }
        });
      });
  }, []);

  // add province layer
  useEffect(() => {
    provinceLayerRef.current = L.layerGroup().addTo(mapRef.current);
  }, []);

  // update province markers
  useEffect(() => {
    provinceLayerRef.current.clearLayers();

    client
      .query(Prismic.Predicates.at('document.type', 'province'), {
        lang: lang,
        pageSize: 30,
      })
      .then(prismicResponse => {
        // response is the response object, response.results holds the documents
        prismicResponse.results.forEach(document => {
          const icon = L.divIcon({
            className: classNames(classes.marker, classes.provinceMarker),
            html: `<div>
  						<svg className=${classes.markerIcon} height="16" width="16" viewBox="0 0 120 120" version="1.1"
            		xmlns="http://www.w3.org/2000/svg">
            		<circle cx="60" cy="60" r="50" fill="#819E84"/>
          		</svg>
  						<div>${RichText.asText(document.data.name)}</div>
  						</div>`,
          });

          const onMarkerClick = e => {
            mapRef.current.setView(e.latlng, 8);
            destinationLayerRef.current.addTo(mapRef.current);
            mapRef.current.removeLayer(regionLayerRef.current);
            mapRef.current.removeLayer(provinceLayerRef.current);
          };

          if (
            Object.prototype.hasOwnProperty.call(document.data.localization, 'latitude') &&
            Object.prototype.hasOwnProperty.call(document.data.localization, 'longitude')
          ) {
            const lat = document.data.localization.latitude;
            const lng = document.data.localization.longitude;
            const latLng = L.latLng(lat, lng);
            L.marker(latLng, { icon })
              .addTo(provinceLayerRef.current)
              .on('click', onMarkerClick);
          } else {
            Geocode.fromAddress(`${RichText.asText(document.data.name)}, France`).then(
              geocodeResponse => {
                const { lat, lng } = geocodeResponse.results[0].geometry.location;
                const latLng = L.latLng(lat, lng);

                L.marker(latLng, { icon })
                  .addTo(provinceLayerRef.current)
                  .on('click', onMarkerClick);
              },
              error => {
                console.error(error);
              },
            );
          }
        });
      });
  }, []);

  // add destination layer
  useEffect(() => {
    destinationLayerRef.current = L.layerGroup();
  }, []);

  // update destination markers
  useEffect(() => {
    destinationLayerRef.current.clearLayers();
    client
      .query(Prismic.Predicates.at('document.type', 'destination'), {
        lang: lang,
        pageSize: 100,
      })
      .then(prismicResponse => {
        // response is the response object, response.results holds the documents
        prismicResponse.results.forEach(document => {
          const icon = L.divIcon({
            className: classes.marker,
            html: `<div>
  						<svg className=${classes.markerIcon} height="16" width="16" viewBox="0 0 120 120" version="1.1"
            		xmlns="http://www.w3.org/2000/svg">
            		<circle cx="60" cy="60" r="50" fill="white"/>
          		</svg>
  						<div>${RichText.asText(document.data.name)}</div>
  						</div>`,
          });

          const onMarkerClick = () => {
            // Add content to sidebar
            const destinationName = RichText.asText(document.data.name);
            const featuredImage = document.data.featured_image;
            const buttonLink =
              lang === 'fr-fr'
                ? `<a href="/destination/${document.uid}"><button class=${classes.button}>DÉCOUVRIR</button></a>`
                : `<a href="/${lang}/destination/${document.uid}"><button class=${classes.button}>DISCOVER</button></a>`;
            if (featuredImage.kind === 'document') {
              sidebarRef.current.setContent(
                `<h3>${destinationName}</h3>` +
                  `<div class=${classes.divVideo}>` +
                  `<video class=${classes.video} controls>` +
                  `<source src="${featuredImage.url}" type="video/mp4">` +
                  `</video>` +
                  `</div>` +
                  `${buttonLink}`,
              );
            } else if (featuredImage.kind === 'image') {
              sidebarRef.current.setContent(
                `<h3>${destinationName}</h3>` +
                  `<img class=${classes.thumbnail} src="${featuredImage.url}" />` +
                  `${buttonLink}`,
              );
            } else {
              sidebarRef.current.setContent(`<h3>${destinationName}</h3>${buttonLink}`);
            }
            sidebarRef.current.show();
          };

          if (
            Object.prototype.hasOwnProperty.call(document.data.localization, 'latitude') &&
            Object.prototype.hasOwnProperty.call(document.data.localization, 'longitude')
          ) {
            const lat = document.data.localization.latitude;
            const lng = document.data.localization.longitude;
            const latLng = L.latLng(lat, lng);
            L.marker(latLng, { icon })
              .addTo(destinationLayerRef.current)
              .on('click', onMarkerClick);
          } else {
            Geocode.fromAddress(`${RichText.asText(document.data.name)}, France`).then(
              geocodeResponse => {
                const { lat, lng } = geocodeResponse.results[0].geometry.location;
                const latLng = L.latLng(lat, lng);

                L.marker(latLng, { icon })
                  .addTo(destinationLayerRef.current)
                  .on('click', onMarkerClick);
              },
              error => {
                console.error(error);
              },
            );
          }
        });
      });
  }, []);

  if (typeof window !== 'undefined') {
    return (
      <Fragment>
        <div id="sidebar">
          <h1>Destination</h1>
        </div>
        <div id="map" />
        {children}
      </Fragment>
    );
  }
  return null;
};

export default HomeMap;
