import { container, primaryColor, title } from 'assets/jss/material-kit-react';

const mapStyle = theme => ({
  section: {
    minHeight: '110vh',
    maxHeight: '1600px',
    overflow: 'hidden',
    padding: '70px 0',
    margin: '0',
    border: '0',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '90vh',
      padding: '10px 0',
    },
    backgroundColor: 'rgba(0,0,0,.25)',
    backgroundImage: `url(https://source.unsplash.com/collection/6690818/1600x900)`,
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    backgroundBlendMode: 'multiply',
    borderRadius: '6px',
  },
  container: {
    ...container,
    textAlign: 'center !important',
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  title: {
    ...title,
    color: '#ffffff',
    textShadow: '0 0 10px black',
    fontFamily: 'Quattrocento',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  subtitle: {
    color: '#ffffff',
    fontFamily: 'Quattrocento',
    fontSize: '65%',
    fontWeight: 400,
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  gridContainer: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 5px',
    },
  },
  card: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  video: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0,
  },
  divVideo: {
    position: 'relative',
    display: 'block',
    width: '100%',
    padding: 0,
    overflow: 'hidden',
    '&:before': {
      content: '""',
      paddingTop: '75%',
      display: 'block',
    },
  },
  thumbnail: {
    width: '100%',
    height: 'auto',
  },
  button: {
    minHeight: 'auto',
    minWidth: 'auto',
    backgroundColor: primaryColor,
    color: '#FFFFFF',
    boxShadow:
      '0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)',
    border: 'none',
    borderRadius: '3px',
    position: 'relative',
    padding: '12px 30px',
    margin: '.3125rem 1px',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'uppercase',
    letterSpacing: '0',
    willChange: 'box-shadow, transform',
    transition:
      'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    '&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: primaryColor,
      boxShadow:
        '0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      position: 'relative',
      display: 'inline-block',
      top: '0',
      fontSize: '1.1rem',
      marginRight: '4px',
      verticalAlign: 'middle',
    },
    '& svg': {
      position: 'relative',
      display: 'inline-block',
      top: '0',
      width: '18px',
      height: '18px',
      marginRight: '4px',
      verticalAlign: 'middle',
    },
  },
  marker: {
    marginLeft: '-50px !important',
    marginTop: '-30px',
    color: 'white',
    textAlign: 'center',
    textShadow: '0 0 3px #000000',
    width: '90px !important',
    fontSize: '0.6rem',
    fontWeight: '700',
    lineHeight: '14px',
  },
  markerIcon: {
    width: '24px !important',
  },
  provinceMarker: {
    color: '#819e84 !important',
  },
});

export default mapStyle;
