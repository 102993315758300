import React, { Fragment } from 'react';
// Link with scroll animation
import { Link } from 'react-scroll';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import sloganStyle from 'assets/jss/material-kit-react/components/sloganStyle';

const Slogan = ({ classes, slogan }) => {
  return (
    <Fragment>
      <div className={classes.slogan}>
        <h1 className={classes.title}>Servitours</h1>
        <h2 className={classes.dmc}>Destination Management Company</h2>
        <h3 className={classes.subtitle}>{slogan}</h3>
      </div>
      <div id="scrollDownButton" className={classes.scrollDownButton}>
        <Link
          className="js-scroll-trigger"
          to="our-destinations"
          spy
          smooth
          offset={-70}
          duration={500}
        >
          <span className={classes.blueText} />
          <span className={classes.whiteText} />
          <span className={classes.redText} />
        </Link>
      </div>
    </Fragment>
  );
};

export default withStyles(sloganStyle)(Slogan);
