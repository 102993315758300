import React from 'react';
import clsx from 'clsx';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
// Material icon
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  link: {
    color: 'white',
    textDecoration: 'underline',
    '&:hover,&:focus': {
      color: 'inherit',
    },
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  snackbar: {
    top: '8px',
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles();
  const { className, message, onClose, link, variant, lang, ...other } = props;
  const Icon = InfoIcon;

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <AniLink key="contact" to="/contact" paintDrip hex="#819E84">
          <Button key="undo" color="secondary" size="small">
            {lang === 'fr-fr' ? `NOUS CONTACTER` : `CONTACT US`}
          </Button>
        </AniLink>,
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

const HomeSnackbar = ({lang}) => {
  const [state, setState] = React.useState({
    open: true,
    vertical: 'bottom',
    horizontal: 'left',
  });

  const { vertical, horizontal, open } = state;

  const message = (lang === 'fr-fr') ? `Notre site internet offre un avant-goût des innombrables lieux et possibilités offerts par Servitours. Pourquoi ne pas prendre contact pour plus d'informations ?` : `Our website offers a taster into the endless venues and possibilities available through Servitours. Why not make contact for further information ?`;

  function handleClose() {
    setState({ ...state, open: false });
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      key={`${vertical},${horizontal}`}
      open={open}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
    >
      <MySnackbarContentWrapper
        variant="info"
        onClose={handleClose}
        message={message}
        lang={lang}
      />
    </Snackbar>
  );
};

export default HomeSnackbar;
