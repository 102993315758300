import React from 'react';
import { RichText } from 'prismic-reactjs';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import AddToPhotos from '@material-ui/icons/AddToPhotos';
import Palette from '@material-ui/icons/Palette';
import Public from '@material-ui/icons/Public';
import StarRateOutlined from '@material-ui/icons/StarRateOutlined';
import OfflinePin from '@material-ui/icons/OfflinePin';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InfoArea from 'components/InfoArea/InfoArea';
import Button from 'components/CustomButtons/Button';

import aboutStyle from 'assets/jss/material-kit-react/views/homePageSections/aboutStyle';

const AddIcon = () => {
  return <AddToPhotos fontSize="large" />;
};

const PaletteIcon = () => {
  return <Palette fontSize="large" />;
};

const WorldIcon = () => {
  return <Public fontSize="large" />;
};

const StarIcon = () => {
  return <StarRateOutlined fontSize="large" />;
};

const CheckIcon = () => {
  return <OfflinePin fontSize="large" />;
};

const icons = {
  AddIcon,
  PaletteIcon,
  WorldIcon,
  StarIcon,
  CheckIcon,
};

const SectionAbout = ({ classes, data, lang, title }) => {
  const qualities = { data };
  
  return qualities.data ? (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>{RichText.asText(title.raw)}</h2>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer justifyContent="space-evenly">
          {qualities.data.map(document => (
            <GridItem xs={12} sm={12} md={4} key={document.id}>
              <InfoArea
                title={RichText.asText(document.title.raw)}
                description={RichText.asText(document.description.raw)}
                icon={icons[document.icon]}
                iconColor="success"
                vertical
              />
            </GridItem>
          ))}
        </GridContainer>
        <GridContainer justifyContent="center">
          <GridItem>
              {lang === 'fr-fr' ? (
              <AniLink to={`/about`} className={classes.navLink} paintDrip hex="#819E84">
                <Button size="lg" color="primary" className={classes.button}>
                  EN SAVOIR PLUS SUR SERVITOURS
                </Button>
              </AniLink>
              ) : (
              <AniLink to={`/${lang}/about`} className={classes.navLink} paintDrip hex="#819E84">
                <Button size="lg" color="primary" className={classes.button}>
                  LEARN MORE ABOUT SERVITOURS
                </Button>
              </AniLink>
              )}
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="space-evenly">
          <GridItem xs={3}>
            <hr style={{ backgroundColor: '#4169e1', border: '1px solid #4169e1' }} />
          </GridItem>
          <GridItem xs={3}>
            <hr style={{ backgroundColor: '#ffffff', border: '1px solid #ffffff' }} />
          </GridItem>
          <GridItem xs={3}>
            <hr style={{ backgroundColor: '#ec1920', border: '1px solid #ec1920' }} />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  ) : null;
};

export default withStyles(aboutStyle)(SectionAbout);
