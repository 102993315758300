const sloganStyle = theme => ({
  slogan: {
    display: 'block',
    position: 'absolute',
    right: '15%',
    left: '15%',
    zIndex: 10,
    color: '#ffffff',
    textAlign: 'center',
    textShadow: '2px 0 10px black',
  },
  title: {
    fontSize: '4.2rem',
    fontWeight: '600',
    position: 'relative',
    fontFamily: 'Great Vibes',
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  dmc: {
    fontSize: '1.5rem',
    position: 'relative',
    marginTop: -15,
    marginBottom: 0,
    textShadow: '2px 5px 5px black',
    [theme.breakpoints.down('xs')]: {
      marginTop: -5,
      fontSize: '1rem',
    },
  },
  subtitle: {
    fontSize: '2rem',
    margin: '20px 0 0',
    display: 'inline-block',
    position: 'relative',
    textShadow: '2px 5px 5px black',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  scrollDownButton: {
    zIndex: 4,
    '& a': {
      paddingTop: '8px',
    },
    '& a span': {
      position: 'absolute',
      top: '75%',
      left: '50%',
      width: '24px',
      height: '24px',
      marginLeft: '-12px',
      borderLeft: '2px solid',
      borderBottom: '2px solid',
      transform: 'rotate(-45deg)',
      animation: '$scrollDownAnimation 2s infinite',
      opacity: 0,
      boxSizing: 'border-box',
      zIndex: 10,
      [theme.breakpoints.down('xs')]: {
        width: '12px',
        height: '12px',
      },
    },
    '& a span:nth-of-type(1)': {
      animationDelay: '0s',
    },
    '& a span:nth-of-type(2)': {
      top: 'calc(75% + 16px)',
      animationDelay: '.15s',
      [theme.breakpoints.down('xs')]: {
        top: 'calc(75% + 8px)',
      },
    },
    '& a span:nth-of-type(3)': {
      top: 'calc(75% + 32px)',
      animationDelay: '.30s',
      [theme.breakpoints.down('xs')]: {
        top: 'calc(75% + 16px)',
      },
    },
  },
  blueText: {
    color: '#2196f3',
  },
  redText: {
    color: '#f44336',
  },
  whiteText: {
    color: '#fff',
  },
  '@keyframes scrollDownAnimation': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
});

export default sloganStyle;
