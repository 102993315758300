import React from 'react';
import { RichText } from 'prismic-reactjs';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
// HomeMap
import HomeMap from 'components/HomeMap/HomeMap';

import mapStyle from 'assets/jss/material-kit-react/views/homePageSections/mapStyle';

const SectionMap = ({ classes, description, lang, title }) => {
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h2 id="our-destinations" className={classes.title}>
          {RichText.asText(title.raw)}
          <div className={classes.subtitle}>{RichText.asText(description.raw)}</div>
        </h2>
        <GridContainer justifyContent="center" className={classes.gridContainer}>
          <GridItem xs={12}>
            <Card className={classes.card}>
              <HomeMap classes={classes} lang={lang} />
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default withStyles(mapStyle)(SectionMap);
