import { title } from 'assets/jss/material-kit-react';

const aboutStyle = theme => ({
  section: {
    padding: '10px 0',
    textAlign: 'center',
    background:
      'linear-gradient(180deg, rgba(224,224,224,1) 0%, rgba(224,224,224,1) 60%, rgba(255,255,255,1) 100%)',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0',
    },
  },
  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
    fontFamily: 'Quattrocento',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  button: {
    marginTop: '50px',
  },
});

export default aboutStyle;
