import React, { useEffect } from 'react';
import { RichText } from 'prismic-reactjs';
// react component for creating beautiful carousel
import Carousel from 'react-slick';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';

import { ChevronLeft, ChevronRight } from 'mdi-material-ui/';
import { FormatQuoteOpen, FormatQuoteClose } from 'mdi-material-ui/light';

import testimonialsStyle from 'assets/jss/material-kit-react/views/homePageSections/testimonialsStyle';

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <ChevronLeft
    fontSize="large"
    {...props}
    className={`slick-prev slick-arrow${currentSlide === 0 ? ' slick-disabled' : ''}`}
    aria-hidden="true"
    aria-disabled={currentSlide === 0}
    type="button"
  />
);
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <ChevronRight
    fontSize="large"
    {...props}
    className={`slick-next slick-arrow${currentSlide === slideCount - 1 ? ' slick-disabled' : ''}`}
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1}
    type="button"
  />
);

const SectionTestimonials = ({ classes, data, title, lang }) => {

  const settings = {
    className: 'testimonials-slider',
    infinite: true,
    speed: 500,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  };

  useEffect(() => {}, [])

  const testimonials = { data };
  
  return testimonials.data ? (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem xs={12}>
          <h2 className={classes.title}>{RichText.asText(title.raw)}</h2>
        </GridItem>
      </GridContainer>
      <div className={classes.container}>
        <GridContainer className={classes.testimonial}>
          <GridItem xs={12} sm={12} md={8} className={classes.marginAuto}>
            {testimonials.data.length > 1 ? (
              <Carousel {...settings}>
                {testimonials.data.map(document => (
                  <div key={document.customer.text}>
                    {document.testimonial ? (
                      <div className={classes.quote}>
                        <FormatQuoteOpen fontSize="large" />
                        <div className={classes.text}>
                          {RichText.render(document.testimonial.raw)}
                        </div>
                        <FormatQuoteClose fontSize="large" />
                      </div>
                    ) : null}
                    {document.customer ? (
                      <h4 className={classes.customer}>
                        - {RichText.asText(document.customer.raw)}
                      </h4>
                    ) : null}
                    {document.pdf ? (
                      <a href={document.pdf.url} target="_blank" rel="noopener noreferrer">
                        <Button size="lg" color="primary" className={classes.button}>
                          {lang === 'fr-fr' ? `LIRE PLUS` : `READ MORE`}
                        </Button>
                      </a>
                    ) : null}
                  </div>
                ))}
              </Carousel>
            ) : (
              <div>
                {testimonials.data[0].testimonial ? (
                  <div className={classes.quote}>
                    <FormatQuoteOpen fontSize="large" />
                    <div className={classes.text}>
                      {RichText.render(testimonials.data[0].testimonial.raw)}
                    </div>
                    <FormatQuoteClose fontSize="large" />
                  </div>
                ) : null}
                {testimonials.data[0].customer ? (
                  <h4 className={classes.customer}>
                    - {RichText.asText(testimonials.data[0].customer.raw)}
                  </h4>
                ) : null}
                {testimonials.data[0].pdf ? (
                  <a href={testimonials.data[0].pdf.url} target="_blank" rel="noopener noreferrer">
                    <Button size="lg" color="primary" className={classes.button}>
                      READ MORE
                    </Button>
                  </a>
                ) : null}
              </div>
            )}
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="space-evenly">
          <GridItem xs={3}>
            <hr style={{ backgroundColor: '#4169e1', border: '1px solid #4169e1' }} />
          </GridItem>
          <GridItem xs={3}>
            <hr style={{ backgroundColor: '#ffffff', border: '1px solid #ffffff' }} />
          </GridItem>
          <GridItem xs={3}>
            <hr style={{ backgroundColor: '#ec1920', border: '1px solid #ec1920' }} />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  ) : null;
};

export default withStyles(testimonialsStyle)(SectionTestimonials);
