import { container } from 'assets/jss/material-kit-react';

const homePageStyle = theme => ({
  container: {
    zIndex: '12',
    ...container,
    textAlign: 'center',
  },
  brand: {
    color: '#FFFFFF',
    textAlign: 'left',
  },
  title: {
    fontSize: '4.2rem',
    fontWeight: '600',
    display: 'inline-block',
    position: 'relative',
  },
  subtitle: {
    fontSize: '1.313rem',
    maxWidth: '500px',
    margin: '10px 0 0',
  },
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
  },
  mainRaised: {
    margin: '-60px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down('xs')]: {
      margin: '-40px 0px 0px',
    },
  },
  link: {
    textDecoration: 'none',
  },
  textCenter: {
    textAlign: 'center',
  },
  homeSlider: {
    height: '90vh',
  },
  marginAuto: {
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
  },
  homeSlide: {
    height: '100vh',
    maxHeight: '1600px',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    margin: '0',
    padding: '0',
    border: '0',
    display: 'flex !important',
    alignItems: 'center',
    '&:before': {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      zIndex: 2,
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.25) !important',
    },
    '&:after': {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      zIindex: 2,
      position: 'absolute',
    },
    [theme.breakpoints.down('xs')]: {
      height: '40vh',
    },
  },
  slideImage: {
    width: '100%',
    height: '100%',
  },
});

export default homePageStyle;
